import React from 'react'
import { Logo } from './icons'
import { Link } from "../../plugins/gatsby-plugin-atollon"

const Header = ({ pageContext }) => {

  const [menu, setMenu] = React.useState(false)
  const [offCanvasListings, setOffCanvasListings] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [homeHeaderScrolled, setHomeHeaderScrolled] = React.useState(false)
  const [prevScroll, setPrevScroll] = React.useState(0)

  let isScrolling

  const onScroll = (e) => {

    let scr, hhScrolled, prvScroll

    const curScroll = window.scrollY || document.scrollTop
    const bannerHeight = document?.querySelector('.home-banner')?.offsetHeight
    scr = curScroll > 1

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (curScroll > 1) {
      scr = true
    } else {
      scr = false
    }

    // Bottom of page
    if (bannerHeight) {
      if (curScroll > bannerHeight - 80) {
        hhScrolled = true
      } else {
        hhScrolled = false
      }
    } else {
      hhScrolled = false
    }

    prvScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setHomeHeaderScrolled(hhScrolled)
      setPrevScroll(prvScroll)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollDetect, false)
    return () => {
      window.removeEventListener('scroll', scrollDetect, false)
    }
  }, [])

  let commercial = false

  let props = {
    onClick: () => setMenu(false),
    activeClassName: 'active',
  }

  let headerClass = 'header'
  if ( homeHeaderScrolled ) headerClass += ' header--home-banner-scrolled'
  if ( scrolled ) headerClass += ' header--scrolled'
  if ( pageContext.header ) headerClass += ` header--${pageContext.header}`

  if ( commercial ) {
    headerClass += ' commercial-header'
    return (
      <>
        <header className={headerClass}>
          <div className='commercial-header__inner'>
            <Link to='/commercial/' title='Mogg' className='commercial-header__logo' {...props}>
              <Logo />
              <span>Commercial</span>
            </Link>
            <nav className='commercial-header__nav'>
              <ul>
                <li className='commercial-header__listings'>
                  <Link to='/commercial/buy/' {...props}>Listings</Link>
                  <ul className='commercial-header__subnav'>
                    <li><Link to='/commercial/lease/' {...props}>For Lease</Link></li>
                    <li><Link to='/commercial/buy/' {...props}>For Sale</Link></li>
                    <li><Link to='/commercial/leased/' {...props}>Leased</Link></li>
                    <li><Link to='/commercial/sold/' {...props}>Sold</Link></li>
                  </ul>
                </li>
                <li><Link to='/commercial/about/' {...props}>About</Link></li>
                <li><Link to='/commercial/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={() => setMenu(!menu)} className={ menu ? 'commercial-header__hamburger active' : 'commercial-header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas off-canvas--${pageContext.header}${ menu && ' off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><button className='off-canvas__listings-open' onClick={() => setOffCanvasListings(!offCanvasListings)}>Listings</button></li>
                <li><Link to='/commercial/about/' {...props}>About</Link></li>
                <li><Link to='/commercial/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <nav className={`off-canvas__listings${offCanvasListings ? ' off-canvas__listings--active' : ''}`}>
              <h4>Listings</h4>
              <ul>
                <li><Link to='/commercial/lease/' {...props}>For Lease</Link></li>
                <li><Link to='/commercial/buy/' {...props}>For Sale</Link></li>
                <li><Link to='/commercial/leased/' {...props}>Leased</Link></li>
                <li><Link to='/commercial/sold/' {...props}>Sold</Link></li>
              </ul>
              <button className='off-canvas__listings-back' onClick={() => setOffCanvasListings(!offCanvasListings)}>{`<< Back`}</button>
            </nav>
            <div className='off-canvas__contact'>
              <p>
                <span>Contact</span>
                <Link to='mailto:info@mogg.commercial'>info@mogg.commercial</Link>
              </p>
              <ul>
                <li><Link to='https://www.instagram.com/moggproperty/'>Instagram</Link></li>
                <li><Link to='https://www.facebook.com/moggproperty/'>Facebook</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <header className={headerClass}>
        <div className='header__inner'>
          <nav className={`header__nav${menu ? ' header__nav--active' : ''}`}>
            <button onClick={() => setMenu(!menu)} className='header__menu'>
              Menu
            </button>
            <div className='header__desktop-nav'>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
                <li>
                  <Link to='/buy/' {...props}>Property</Link>
                  <ul>
                    <li><Link to='/buy/' {...props}>Residential</Link></li>
                    <li><Link to='/sold/' {...props}>Sold</Link></li>
                    <li><Link to='/buy-commercial/' {...props}>Commercial</Link></li>
                    <li><Link to='/services/' {...props}>Styling</Link></li>
                    <li><Link to='/appraisals/' {...props}>Sell with us</Link></li>
                  </ul>
                </li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/journal/' {...props}>Media</Link></li>
              </ul>
            </div>
            <div className='header__off-canvas'>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
                <li><Link to='/buy/' {...props}>Residential</Link></li>
                <li><Link to='/sold/' {...props}>Sold</Link></li>
                <li><Link to='/buy-commercial/' {...props}>Commercial</Link></li>
                <li><Link to='/services/' {...props}>Services</Link></li>
                <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                <li><Link to='/journal/' {...props}>Journal</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='https://www.youtube.com/channel/UCWhhA5-jqiYCAiUqrY4TglQ' target='_blank' rel='noopener noreferrer' {...props}>Youtube</Link></li>
              </ul>
            </div>
          </nav>
          <Link to='/' title='Mogg' className='header__logo' {...props}>
            <Logo />
          </Link>
          <div className='header__button'>
            <ul>
              <li><Link to='tel:1300888011'>1300 888 011</Link></li>
              <li><Link to='https://www.youtube.com/channel/UCWhhA5-jqiYCAiUqrY4TglQ' target='_blank' rel='noopener noreferrer' {...props}>Youtube</Link></li>
              <li><Link to='/contact/' {...props}>Enquire</Link></li>
            </ul>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
