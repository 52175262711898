import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    query {
      ...GlobalData
      newsletterForm: datoCmsForm(title: {eq: "Newsletter"}) {
        ...FormProps
      }
    }
  `)
}

export default useLayoutQuery