import React from 'react'
import { Logo } from './icons'
import { Link } from "../../plugins/gatsby-plugin-atollon"
import FormComponent from './form'

const Footer = ({pageContext, data, newsletter}) => {

  let commercial = false

  if ( commercial ) {
    return (
      <>
        <section className='commercial-back'>
          <div className='commercial-back__inner'>
            <h4>Looking for residential?</h4>
            <div className='commercial-back__content'>
              <p>Jump across to our residential offering for stylish properties and homes.</p>
              <Link className='btn btn--burgundy' to='/'>Visit mogg.property</Link>
            </div>
          </div>
        </section>
        <footer className='commercial-footer'>
          <div className='commercial-footer__inner'>
            <div className='commercial-footer__top'>
              <div className='commercial-footer__col'>
                &nbsp;
              </div>
              <div className='commercial-footer__col'>
                <Link to='/' title='Mogg' className='commercial-footer__logo'>
                  <Logo color='#FFFFFF' />
                  <span>Commercial</span>
                </Link>
                <p>
                  <span>Contact</span>
                  <Link to='mailto:adam@mogg.property'>adam@mogg.property</Link>
                </p>
                <p>
                  <span>Address</span>
                  PO BOX 543<br />Maroochydore QLD 4558
                </p>
              </div>
            </div>
            <div className='commercial-footer__bottom'>
              <div className='commercial-footer__mobile'>
                <ul>
                  <li><Link to='https://instagram.com/moggproperty'>Instagram</Link></li>
                  <li><Link to='https://facebook.com/moggproperty'>Facebook</Link></li>
                </ul>
              </div>
              <div className='commercial-footer__col'>
                <ul>
                  <li>© Mogg Property 2024.</li>
                </ul>
              </div>
              <div className='commercial-footer__col'>
                <ul className='commercial-footer__social'>
                  <li><Link to='https://instagram.com/moggproperty'>Instagram</Link></li>
                  <li><Link to='https://facebook.com/moggproperty'>Facebook</Link></li>
                </ul>
                <ul className='commercial-footer__social'>
                  <li>Design by <Link to='https://atollon.com.au' target='_blank' rel='noopener noreferrer'>Atollon</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
  return (
    <>
      {/* <section className='residential-back'>
        <div className='residential-back__inner'>
          <h4>Looking for commercial?</h4>
          <div className='residential-back__content'>
            <p>Get down to business with our expert commercial division.</p>
            <Link className='btn btn--white-outline' to='/commercial/'>Visit commercial</Link>
          </div>
        </div>
      </section> */}
      <footer className='footer footer--burgundy'>
        <div className='footer__top'>
          <div className='footer__inner'>
            <div className='footer__col'>
              <div className='footer__newsletter'>
                <h4>Sign up to our newsletter</h4>
                <FormComponent form={newsletter} />
              </div>
              <Link to='/' className='footer__logo footer__logo--desktop'>
                <Logo color='#381F1F' />
              </Link>
            </div>
            <div className='footer__col footer__col--right'>
              <div className='footer__item'>
                <h4>Contact</h4>
                <ul>
                  <li>P.O. Box 543</li>
                  <li>Maroochydore, QLD 4558</li>
                  <li><Link to='mailto:info@mogg.property'>info@mogg.property</Link></li>
                  <li><Link to='tel:1300888011'>1300 888 011</Link></li>
                </ul>
              </div>
              <div className='footer__item'>
                <h4>Follow</h4>
                <ul>
                  <li><Link to='https://www.instagram.com/moggproperty/'>Instagram</Link></li>
                  <li><Link to='https://www.facebook.com/moggproperty/'>Facebook</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <Link to='/' className='footer__logo footer__logo--mobile'>
            <Logo color='#381F1F' />
          </Link>
        </div>
        <div className='footer__bottom'>
          <div className='footer__inner'>
            <div className='footer__col'>
              <ul>
                <li>Design by <Link to='https://atollon.com.au' target='_blank' rel='noopener noreferrer'>Atollon</Link></li>
              </ul>
            </div>
            <div className='footer__col footer__col--right'>
              <div className='footer__item'>
                <ul>
                  <li>© Mogg Property 2024.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
