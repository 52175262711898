import React from 'react'
import {Form} from "../../plugins/gatsby-plugin-atollon";

const FormComponent = (props) => {
  return (
    <Form
      schema={props.form}
      renderField={(field) => {
        if (field.type === "text" || field.type === "email" || field.type === "tel") {
          return (
            <div className='form__text'>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <input
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                type={field.label === 'Property' ? 'hidden' : field.type}
                defaultValue={field.label === 'Property' ? props.property : undefined}
                id={field.id}
                autoComplete={field.autoComplete}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "textarea") {
          return (
            <div className='form__textarea'>
              <label htmlFor={field.id}>
                  {field.label}
              </label>
              <textarea
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "select") {
          return (
            <div className='form__select'>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <select
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
                defaultValue=""
              >
                <option value="" disabled>{field.placeholder}</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "radio") {
          return (
            <div className='form__radio'>
              <div>
                {field.label}
              </div>
              {field.options.map((option) => (
                <div>
                  <input
                    id={option.id}
                    type="radio"
                    value={option.value}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    name={field.name}
                    aria-required={field.required}
                    disabled={field.disabled}
                    aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                    aria-invalid={field?.invalid}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              ))}
              {field.hasErrors && (
                  <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "checkbox") {
          return (
            <div className={`form__checkbox${field.label === 'Property Management' ? ' form__checkbox--last' : ''}`}>
              <input
                type="checkbox"
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              <label htmlFor={field.id}>
                {field.label}
              </label>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        return (
          <div>field</div>
        )
      }}
      renderApiErrors={({ errors }) => (
        <div>{errors.join(", ")}</div>
      )}
      renderSubmitButton={({ children }) => ( <div className='form__submit'><button className='btn' type="submit">{children}</button></div>) }
      renderLoadingSpinner={() => <div>Loading</div>}
    />
  )
}

export default FormComponent
