import React from 'react'

export const Logo = ({ color }) => (
  <svg width="290" height="57" viewBox="0 0 290 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M80.839 50.2228C82.1813 52.8045 83.9631 54.3288 86.1877 54.7922V55.3347H53.3262V54.7922C55.4959 54.3288 57.0854 52.9212 58.0913 50.5695C59.1007 48.2179 59.6293 45.5194 59.6808 42.4709L58.1325 13.5608L40.6959 53.9408H39.611L12.5548 13.4098L20.6157 39.7622C22.1125 44.2561 23.6643 47.7956 25.2641 50.3807C26.8674 52.9624 28.7006 54.4352 30.7674 54.7957V55.3381H0L0.0789611 54.7957C4.41839 53.5563 7.07903 48.7775 8.0609 40.4591L10.3096 10.0762L4.65184 1.62739L5.19427 1.08496H26.8948L46.8926 32.0858L55.1045 1.08496H80.9145V1.62739C79.4177 1.88487 78.2779 2.49253 77.5054 3.45036C76.7296 4.40819 76.3416 5.97025 76.3416 8.13996L78.2779 41.6984C78.6384 44.7985 79.4932 47.6377 80.8355 50.2228" fill={color}/>
    <path d="M137.536 26.5447C131.594 22.4902 124.618 19.0674 116.608 16.2763C108.599 13.4852 101.959 12.0914 96.6895 12.0914C94.4683 12.0914 92.6076 12.5686 91.1073 13.523C89.6105 14.4808 88.8587 15.9673 88.8587 17.9826C88.8587 21.5976 91.8043 25.4598 97.6954 29.5692C103.587 33.6752 110.511 37.1392 118.466 39.9543C126.424 42.7695 132.933 44.177 137.996 44.177C143.63 44.177 146.445 42.1618 146.445 38.1314C146.445 34.4648 143.476 30.6026 137.533 26.5447M87.5404 42.8965C84.9072 38.6326 83.5889 33.713 83.5889 28.1308C83.5889 22.5486 84.9072 17.7216 87.5404 13.4818C90.177 9.24534 94.0495 5.93928 99.1648 3.56015C104.28 1.18101 110.456 -0.00683594 117.69 -0.00683594C124.923 -0.00683594 131.099 1.18101 136.211 3.56015C141.327 5.93928 145.203 9.24534 147.839 13.4818C150.476 17.7182 151.791 22.6035 151.791 28.1308C151.791 33.6581 150.472 38.6326 147.839 42.8965C145.206 47.1604 141.33 50.4802 136.211 52.8559C131.096 55.235 124.92 56.4194 117.69 56.4194C110.46 56.4194 104.28 55.2316 99.1648 52.8559C94.0495 50.4802 90.1735 47.1604 87.5404 42.8965Z" fill={color}/>
    <path d="M221.074 28.2097V28.7521C220.143 28.9581 219.395 29.94 218.825 31.6977C218.255 33.4555 217.974 35.1857 217.974 36.892V56.4228H217.198L212.161 40.0676C210.974 42.4982 209.216 44.9529 206.892 47.4316C204.567 49.9103 201.584 52.0319 197.938 53.7862C194.296 55.544 190.148 56.4194 185.5 56.4194C179.921 56.4194 174.881 55.2178 170.388 52.8147C165.894 50.4115 162.354 47.0024 159.769 42.5841C157.184 38.1657 155.893 33.0366 155.893 27.2004C155.893 21.6697 157.071 16.853 159.419 12.7436C161.771 8.63766 165.217 5.48608 169.766 3.2889C174.312 1.09859 179.739 0 186.042 0C190.227 0 194.388 0.387939 198.522 1.16038C202.655 1.93626 206.232 2.83917 209.257 3.87253C212.278 4.90589 214.335 5.78476 215.419 6.50571L213.868 24.9517H213.016C211.31 22.885 208.186 20.832 203.637 18.7893C199.092 16.75 194.028 15.0953 188.446 13.8285C182.863 12.5651 177.803 11.93 173.254 11.93C168.705 11.93 165.231 12.8089 162.986 14.5632C160.737 16.3209 159.615 18.6451 159.615 21.5392C159.615 24.8968 161.266 27.9728 164.575 30.7639C167.881 33.555 172.314 35.735 177.868 37.3143C183.423 38.89 189.431 39.6797 195.889 39.6797C199.298 39.6797 202.229 39.4737 204.684 39.0583C207.139 38.6463 209.271 37.9219 211.08 36.8886C209.738 35.2853 207.321 33.6065 203.833 31.8522C200.345 30.0945 197.282 29.0611 194.649 28.7521V28.2097H221.077H221.074Z" fill={color}/>
    <path d="M290 28.2097V28.7521C289.07 28.9581 288.321 29.94 287.751 31.6977C287.185 33.4555 286.9 35.1857 286.9 36.892V56.4228H286.124L281.084 40.0676C279.896 42.4982 278.139 44.9529 275.814 47.4316C273.49 49.9103 270.507 52.0319 266.864 53.7862C263.222 55.544 259.075 56.4194 254.423 56.4194C248.844 56.4194 243.804 55.2178 239.31 52.8147C234.817 50.4115 231.277 47.0024 228.692 42.5841C226.107 38.1657 224.816 33.0366 224.816 27.2004C224.816 21.6697 225.99 16.853 228.342 12.7436C230.693 8.63766 234.144 5.48608 238.689 3.2889C243.241 1.09859 248.666 0 254.969 0C259.154 0 263.311 0.387939 267.448 1.16038C271.581 1.93626 275.159 2.83917 278.183 3.87253C281.204 4.90589 283.257 5.78476 284.346 6.50571L282.794 24.9517H281.943C280.236 22.885 277.109 20.832 272.563 18.7893C268.018 16.75 262.954 15.0953 257.372 13.8285C251.793 12.5651 246.729 11.93 242.18 11.93C237.632 11.93 234.161 12.8089 231.912 14.5632C229.663 16.3209 228.541 18.6451 228.541 21.5392C228.541 24.8968 230.196 27.9728 233.502 30.7639C236.808 33.555 241.24 35.735 246.795 37.3143C252.349 38.89 258.354 39.6797 264.815 39.6797C268.224 39.6797 271.159 39.4737 273.614 39.0583C276.069 38.6463 278.2 37.9219 280.006 36.8886C278.664 35.2853 276.247 33.6065 272.759 31.8522C269.271 30.0945 266.209 29.0611 263.576 28.7521V28.2097H290.003H290Z" fill={color}/>
  </svg>
)

export const HourGlass = ({ color }) => (
  <svg width="101" height="525" viewBox="0 0 101 525" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M101 262.173C38.2005 331.868 0 423.991 0 525V0C0.161843 100.75 38.3343 192.627 101 262.173Z" fill={color}/>
  </svg>
)

export const Hexagon = ({ color }) => (
  <svg width="107" height="525" viewBox="0 0 107 525" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M106.25 0H0V262.5V525H106.25L0 262.5L106.25 0Z" fill={color}/>
  </svg>
)

export const Arch = ({ color }) => (
  <svg width="493" height="231" viewBox="0 0 493 231" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M493 0H0V231H34V230.5C34 113.14 129.14 18 246.5 18C363.86 18 459 113.14 459 230.5V231H493V0Z" fill={color} />
  </svg>
)

export const Morph = ({ color }) => (
  <svg id="morph" width="107" height="525" viewBox="0 0 107 525" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="morph-line" d="M0 525, L525 0" fill={color} />
    <path opacity="0" id="morph-hexagon" d="M106.25 0H0V262.5V525H106.25L0 262.5L106.25 0Z" fill={color} />
    <path opacity="0" id="morph-hour-glass" d="M101 262.173C38.2005 331.868 0 423.991 0 525V0C0.161843 100.75 38.3343 192.627 101 262.173Z" fill={color} />
  </svg>
)

export const Sold = () => (
  <svg width="180" height="155" viewBox="0 0 180 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="89.6195" cy="77.2117" rx="72.5053" ry="59.9895" transform="rotate(-6.97303 89.6195 77.2117)" fill="#7FFB4C"/>
    <path d="M68.433 71.1418C70.5407 70.8841 72.3031 71.2163 73.7172 72.113C75.1569 73.0065 76.0012 74.3641 76.2758 76.1828L72.2399 76.8851C72.013 75.2432 70.7563 74.3534 68.9314 74.5766C67.1579 74.7936 66.1883 75.8252 66.3612 77.2389C66.4461 77.9329 66.7629 78.3898 67.3435 78.6579C67.921 78.9003 68.9386 79.115 70.4189 79.2731C72.6985 79.5682 74.4284 80.061 75.5797 80.7289C76.7566 81.3937 77.4442 82.5357 77.6391 84.1293C77.8749 86.0571 77.3858 87.6039 76.1718 88.7698C74.9835 89.9325 73.3469 90.6284 71.2906 90.8799C69.003 91.1597 67.0864 90.8462 65.5407 89.9396C63.9949 89.033 63.0898 87.6045 62.8252 85.6542L66.8354 84.955C67.1263 86.6934 68.7874 87.6903 70.8693 87.4356C72.8999 87.1873 73.8112 86.3193 73.632 84.8542C73.4654 83.4919 72.291 83.0617 69.3499 82.6909C68.2903 82.5596 67.4395 82.4289 66.7686 82.2762C66.0978 82.1234 65.4143 81.8679 64.6957 81.5384C63.2811 80.8505 62.5328 79.6376 62.3159 77.8641C62.1115 76.1934 62.5298 74.7074 63.577 73.4576C64.621 72.182 66.2482 71.4091 68.433 71.1418Z" fill="#352020"/>
    <path d="M94.1897 81.0616C94.4412 83.1178 93.9772 84.8703 92.7979 86.3189C91.641 87.7387 90.0232 88.5887 87.967 88.8402C85.9107 89.0917 84.1357 88.6566 82.6451 87.5607C81.177 86.4358 80.3044 84.8468 80.0529 82.7906C79.7983 80.7086 80.2622 78.9562 81.4191 77.5364C82.6016 76.1135 84.2194 75.2634 86.2757 75.0119C88.3319 74.7604 90.1069 75.1955 91.5718 76.2946C93.0624 77.3906 93.9351 78.9796 94.1897 81.0616ZM89.1559 78.938C88.483 78.342 87.6453 78.1054 86.6686 78.2248C85.6919 78.3443 84.936 78.7759 84.4265 79.5165C83.9139 80.2313 83.7155 81.1687 83.857 82.3253C83.9985 83.482 84.4169 84.3439 85.0867 84.9141C85.7565 85.4844 86.5941 85.7211 87.5709 85.6016C88.5476 85.4822 89.3035 85.0506 89.8161 84.3357C90.3287 83.6208 90.5271 82.6835 90.3856 81.5268C90.2442 80.3702 89.8257 79.5083 89.1559 78.938Z" fill="#352020"/>
    <path d="M99.2845 66.8989L101.727 86.8703L97.9745 87.3293L95.5319 67.3579L99.2845 66.8989Z" fill="#352020"/>
    <path d="M116.172 64.8335L118.615 84.8049L115.093 85.2356L114.927 83.8733C114.298 84.9155 112.943 85.7855 111.17 86.0024C109.293 86.2319 107.672 85.7779 106.31 84.6662C104.974 83.5514 104.178 81.953 103.924 79.871C103.669 77.7891 104.056 76.0461 105.084 74.642C106.138 73.2348 107.602 72.4036 109.478 72.1741C111.149 71.9697 112.465 72.2784 113.404 73.1288L112.445 65.2893L116.172 64.8335ZM110.488 75.3115C108.56 75.5473 107.442 77.0668 107.728 79.4058C108.014 81.7448 109.463 82.9241 111.39 82.6884C113.267 82.4589 114.372 81.0454 114.111 78.912L114.039 78.3208C113.784 76.2389 112.364 75.0821 110.488 75.3115Z" fill="#352020"/>
  </svg>
)

export const Play = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1669 52.5842C10.9875 52.5842 10.8059 52.5405 10.6419 52.453C10.2766 52.2583 10.0469 51.8777 10.0469 51.4642V5.20277C10.0469 4.78933 10.2744 4.40868 10.6419 4.21402C11.0072 4.01933 11.4512 4.04339 11.7947 4.27309L46.1277 27.4041C46.4362 27.6119 46.6221 27.9597 46.6221 28.3338C46.6221 28.7057 46.4362 29.0535 46.1277 29.2635L11.793 52.3946C11.6049 52.5214 11.3861 52.5849 11.1674 52.5849L11.1669 52.5842ZM12.2869 7.3067V49.3597L43.494 28.3332L12.2869 7.3067Z" fill="white"/>
  </svg>
)
